import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import LatestPosts from "../components/latestposts"
import CandidatesBanner from "../components/candidatesbanner"

const index = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title

  return (
    <Layout location={location} title={siteTitle}>
      <Seo title="Home"/>
      <div className="home_intro" id="abc">
        <p>Progressive Politics NI is a political group setup to encourage & promote progressive politicians and political parties in Northern Ireland. It’s time for cross-community politics, which fights for equality, human rights, transparency and a fair quality of life for all.</p>
      </div>
      
      <CandidatesBanner></CandidatesBanner>
      
      <div className="recent_posts">
        <LatestPosts></LatestPosts>
      </div>
      
    </Layout>
  )
}

export default index

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
