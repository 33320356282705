import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { Container, Row, Col } from "react-bootstrap";
import parse from "html-react-parser";

const LatestPosts = () => {
    const RecentPostsQuery  = useStaticQuery(
        graphql`
        query RecentPostsQuery {
            allWpPost(filter: {status: {eq: "publish"}}, limit: 5, sort: {fields:date, order:DESC}) {
                    nodes {
                        id
                        title
                        excerpt
                        date(formatString: "Do MMMM, YYYY")
                        title
                    }
            }
        }
      `)

        const posts = RecentPostsQuery.allWpPost.nodes;
        return ( 
<div>
    <h1>LATEST NEWS</h1>
    <br/>    
    <Container>
        <Row>
            <Col sm>
                <Row><h3>{posts[0].title}</h3></Row>
                <Row><small>{posts[0].date}</small></Row>
                <Row><p class="post_card_excerpt">{parse(posts[0].excerpt)}</p></Row>
            </Col>
            <Col sm>
                <Row><hr class="hr-sm"></hr></Row>
                <Row><h3>{posts[1].title}</h3></Row>
                <Row><small>{posts[1].date}</small></Row>
                <Row><p class="post_card_excerpt">{parse(posts[1].excerpt)}</p></Row>
            </Col>
        </Row>
        <Row>
            <Col sm>
                <Row><hr></hr></Row>
                <Row><h3>{posts[2].title}</h3></Row>
                <Row><small>{posts[2].date}</small></Row>
                <Row><p class="post_card_excerpt">{parse(posts[2].excerpt)}</p></Row>
            </Col>
            <Col sm>
                <Row><hr></hr></Row>
                <Row><h3>{posts[3].title}</h3></Row>
                <Row><small>{posts[3].date}</small></Row>
                <Row><p class="post_card_excerpt">{parse(posts[3].excerpt)}</p></Row>
            </Col>
        </Row>
    </Container>
</div>
        )
}

export default LatestPosts